import React from 'react';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Stack, Box, Typography } from '@mui/material';
import { Trans } from 'gatsby-plugin-react-i18next';

import Layout from '@gaia/Layout';
import Socials from '@gaia/components/Socials';

import contactUsImage from '../images/contact-us.svg';
import ContactForm from '../components/ContactForm';

const Contact = () => {
    return (
        <Layout>
            <SEO title="Contact us" />
            <Stack component="section" sx={{ flexDirection: { md: 'row' }, gap: { xs: 4, md: 7 } }}>
                <Box flexBasis="50%">
                    <Typography variant="h1" sx={{ color: 'primary.main', mb: { xs: 2, md: 4 } }}>
                        <Trans i18nKey="contact_header_contactUs">Contact Us</Trans>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mb: { xs: 2, md: 4 } }}>
                        <Trans i18nKey="contact_subHeader_text">
                            Whether you have an idea to share, are interested in volunteering, would
                            like to partner with us or delete your account and data, we would love
                            to hear from you!
                        </Trans>
                    </Typography>
                    <ContactForm />
                </Box>
                <Box
                    flexBasis="50%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                    gap={2}
                >
                    <Box
                        component="img"
                        src={contactUsImage}
                        alt="Contact us"
                        sx={{ display: 'inline-block', marginBottom: { xs: 2, sm: 4 } }}
                    />
                    <Typography variant="subtitle1">Follow Us on Social Media</Typography>
                    <Socials variant="primary" fontSize="35px" />
                </Box>
            </Stack>
        </Layout>
    );
};

export default Contact;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
